@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"









































































































.question
  &-options
    border: 1px solid rgba($color-ink, .15)
    cursor: pointer
    padding: 0 24px !important
    +space-stack($size-s)
    +flex-space-between

    &.--active
      border-color: $color-primary
      box-shadow: 0 0 0 2px $color-primary

    &.--correct
      border-color: $color-success-light
      box-shadow: 0 0 0 2px $color-success-light

    &.--incorrect
      border-color: $color-danger-light
      box-shadow: 0 0 0 2px $color-danger-light

    &.--disabled
      pointer-events: none

    .feather
      flex-shrink: 0

    ::v-deep img
      width: auto
      max-width: 100%
      height: auto
      display: inline-block

    ::v-deep p
      font-size: 18px
      margin: 20px 0
      line-height: 1.5
      letter-spacing: 0.25px
