@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"


































































$online: #18C57C
$offline: #FF4646
$color-font: #F0F0F2

.status-connection
  width: 100%
  background: $online
  color: $color-white
  padding: 16px
  text-align: center

  +mq-s
    text-align: left

  &--offline
    background: $offline

  p
    font-size: 18px
    color: $color-font
    +flex-center

    i
      margin-right: 16px
      min-width: 30px
