@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




























































.question-content
  width: 100%
  max-width: $question-max-width
  margin: 0 auto

  &__html
    margin-bottom: 50px

    .sas-skeleton-loader:not(:last-child)
      margin-bottom: 20px

  &__options
    margin-bottom: 10px
    border: 1px solid rgba($color-ink, .15)

  .question-footer
    position: fixed
    bottom: 0
    left: 0
    right: 0
    background: $color-white
    box-shadow: 0 0 8px rgba($color-ink, .25)
    padding: 16px

    &__wrapper
      +mq-xs
        max-width: 100%

      +mq-s--mf
        +flex-space-between

    &__actions
      width: 100%
      max-width: 248px

      +mq-xs
        display: none

    &__help
      +flex-space-between

      .sas-skeleton-loader:not(:last-child)
        margin-right: 16px
