@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"







































































































.question-content
  width: 100%
  max-width: $question-max-width
  margin: 0 auto

  ::v-deep img:hover
    cursor: zoom-in

  &__html,
  &__explanation
    padding-bottom: $size-m

    ::v-deep img
      +embedded-image

    ::v-deep p
      font-size: $font-size-heading-6 !important
      +embedded-paragraph

  &__explanation
    margin-top: $size-xl

    &__header
      +flex-center-start
      color: $color-primary
      border-bottom: 2px solid $color-primary
      padding-bottom: $size-xs

      h4
        margin-left: $size-xs

    &__video
      +proportional-video
      margin-top: $size-m

      ::v-deep iframe
        +proportional-video-iframe
