@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"
.viewer-close::before
  background-image: url('~@/assets/close-icon.svg')
  background-repeat: no-repeat
  background-size: 24px
  width: 24px
  height: 24px
  content: ''
  position: absolute
  bottom: 8px
  left: 8px

.viewer-button
  border-radius: 50%
  cursor: pointer
  width: 40px
  height: 40px
  overflow: hidden
  position: absolute
  transition: background-color 0.15s

  &:focus, &:hover
    background-color: rgba(0, 0, 0, 0.8)

  &.viewer-close
    background-color: #1E2124 !important
    right: 4px
    top: 4px
    border-radius: 6px

.viewer-zoom-in
  background-image: url('~@/assets/zoom-in.svg')

.viewer-zoom-out
  background-image: url('~@/assets/zoom-out.svg')

.viewer-one-to-one,
.viewer-reset,
.viewer-prev,
.viewer-play,
.viewer-next,
.viewer-rotate-left,
.viewer-rotate-right,
.viewer-flip-horizontal,
.viewer-flip-vertical,
.viewer-fullscreen
  display: none

.viewer-container
  bottom: 0
  direction: ltr
  font-size: 0
  left: 0
  line-height: 0
  overflow: hidden
  position: absolute
  right: 0
  top: 0
  -webkit-tap-highlight-color: transparent
  touch-action: none
  -webkit-touch-callout: none
  user-select: none

  &::-moz-selection,
  *::-moz-selection,
  &::selection,
  *::selection
    background-color: transparent

  img
    display: block
    height: auto
    max-height: none !important
    max-width: none !important
    min-height: 0 !important
    min-width: 0 !important
    width: 100%

.viewer-canvas
  bottom: 0
  left: 0
  overflow: hidden
  position: absolute
  right: 0
  top: 0

  > img
    height: auto
    margin: 15px auto
    max-width: 90% !important
    width: auto

.viewer-footer
  bottom: 0
  left: 0
  overflow: hidden
  position: absolute
  right: 0
  text-align: center

.viewer-toolbar
  margin-bottom: 24px

  > ul
    display: inline-block
    background-color: #1E2124
    box-shadow: 0px 16px 24px rgba(52, 60, 88, 0.4)
    border-radius: 6px
    margin: 0 auto 5px
    overflow: hidden
    padding: 8px 16px

    >
      li
        margin: 0 16px
        border-radius: 50%
        cursor: pointer
        float: left
        height: 24px
        overflow: hidden
        transition: background-color 0.15s
        width: 24px

        &::before
          margin: 2px

        + li
          margin-left: 1px

.viewer-fixed
  position: fixed

.viewer-open
  overflow: hidden

.viewer-show
  display: block

.viewer-hide
  display: none

.viewer-backdrop
  background-color: rgba(0, 0, 0, 0.8)

.viewer-invisible
  visibility: hidden

.viewer-move
  cursor: move
  cursor: -webkit-grab
  cursor: grab

.viewer-fade
  opacity: 0

.viewer-in
  opacity: 1

.viewer-transition
  transition: all 0.3s

@-webkit-keyframes viewer-spinner
  0%
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

@keyframes viewer-spinner
  0%
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

.viewer-loading::after
  -webkit-animation: viewer-spinner 1s linear infinite
  animation: viewer-spinner 1s linear infinite
  border: 4px solid rgba(255, 255, 255, 0.1)
  border-left-color: rgba(255, 255, 255, 0.5)
  border-radius: 50%
  content: ''
  display: inline-block
  height: 40px
  left: 50%
  margin-left: -20px
  margin-top: -20px
  position: absolute
  top: 50%
  width: 40px
  z-index: 1
