@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"

























































































































































































































.question-footer
  position: fixed
  bottom: 0
  left: 0
  right: 0
  background: $color-white
  box-shadow: 0 0 8px rgba($color-ink, .25)
  padding: $size-s
  transition: background $speed-fast, transform $speed-fast

  +mq-xs
    transform: translateY(62px)

  &-enter,
  &-leave-to
    transform: translateY(100%)

  &.--zindex
    +mq-s--mf
      z-index: 101

  &.--active
    +mq-xs
      transform: translateY(0)

  &.--wrong,
  &.--right
    box-shadow: none

    +mq-xs
      transform: translateY(0)

  &.--right
    background: tint-color($color-success-lightest, 25%)

  &.--wrong
    background: tint-color($color-danger-lightest, 25%)

  &__wrapper
    +flex-space-between
    flex-wrap: wrap
    +mq-xs
      max-width: 100%

    +mq-s--mf
      +flex-space-between

  &__help
    flex-grow: 1

  &__button.--answer
    flex:
      grow: 1
      basis: 100%
    margin-top: $size-s

    +mq-s--mf
      flex:
        basis: auto
        grow: 0
      width: 240px
      margin:
        top: 0
        left: $size-s

  &__button.--next
    +mq-s--mf
      margin-left: $size-s

  &__feedback
    flex:
      basis: 100%
      grow: 1
    +flex-center-start
    margin-bottom: $size-m

    +mq-m--mf
      margin-bottom: 0
      flex-basis: auto

    &__emoji
      width: $size-xl
      height: $size-xl
      flex-shrink: 0
      border-radius: 100%
      margin-right: $size-s
      +flex-center

      &.--right
        background: transparentize($color-success-lighter, .5)

      &.--wrong
        background: transparentize($color-danger-lighter, .5)

      img
        display: block
        width: 36px
        height: 36px

    &__text
      &.--right
        h4,
        p
          color: $color-success-dark

      &.--wrong
        h4,
        p
          color: $color-danger-dark

    &__content
      +flex-center

    &__finish-text
      margin: $size-xs
