@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"


































































































































































.drawer
  transition: transform $speed-fast ease-in-out !important
  height: initial
  max-height: 100%
  padding-bottom: $size-l

  +mq-s--mf
    padding-bottom: 132px

  &.--position-bottom
    border-radius: $size-s $size-s 0 0

  &-header
    padding: $size-s 0
    border-bottom: 1px solid $color-ink-lightest
    background: $color-white
    position: sticky
    top: 0
    z-index: 100

    &__wrapper
      +flex-space-between

      +mq-xl--mf
        padding:
          left: $size-l
          right: $size-l

    &__title
      +flex-center-start

      img
        margin-right: $size-s

  &-content
    padding: $size-s 0

    +mq-xl--mf
      padding:
        left: $size-l
        right: $size-l

    &__title
      margin-bottom: $size-s

    &__video
      +proportional-video

      ::v-deep iframe
        +proportional-video-iframe

    &__book
      padding: $size-xl 0 $size-s

      +mq-xs
        +flex-center
        flex-flow: column

      h5
        margin-bottom: $size-s
