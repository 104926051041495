@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";





















































































































































































































































































































































































.assignment {
  background: $color-white;
  width: 100%;
  min-height: calc(100vh - #{$menu-height});

  &__loading {
    @include cover;
    @include flex-center;
  }

  &__content {
    min-height: calc(100vh - #{$menu-height});
    padding: $size-m $size-l $end-space;

    &.--center {
      @include flex-column-center;
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $size-s;
      max-width: $question-max-width;
      margin: 0 auto $size-s auto;

      &__text {
        flex-grow: 1;
      }
    }

    &__report-question-problem__wrapper {
      width: 100%;
      max-width: $question-max-width;
      margin: $size-xl auto;
    }
  }
}
